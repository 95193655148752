import React from "react";
import Layout from "../components/Layout";

import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import comp3 from "../images/compressed/comp3.png";

function MetaTags() {
  return (
    <Helmet>
      <title>{`Not Found - Pantano Music`}</title>
      <meta name="description" content="Not found - Pantano music" />
      <meta property="og:title" content="not found - Pantano Music" />
      <meta property="og:description" content="Not found - Pantano music" />
      <meta property="og:image" content={comp3} />
      <meta property="og:url" content="https://www.pantanomusic.com/music" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default function NotFound() {
  return (
    <Layout>
      <MetaTags />
      <h1>Page not found</h1>
    </Layout>
  );
}
